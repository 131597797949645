<template>
  <div id="app" class="url">
    <!-- <el-row>
      <el-col :span="24" style="margin-bottom: 10px">
        <el-page-header @back="goBack" content="公司管理系统"> </el-page-header>
      </el-col>
    </el-row> -->

    <!-- 公司结构 -->
    <div>
      <el-input placeholder="输入关键字进行搜索..." prefix-icon="el-icon-search" v-model="filterText" style="margin: 0 10px 5px 0; width: 300px">
      </el-input>
      <el-button type="success" @click="dialogVisible1 = true">添加新公司</el-button>

      <el-dialog title="添加新公司" :visible.sync="dialogVisible1" width="30%">

        <el-input v-model="form.info" placeholder="请输入公司名称"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="AddNewCompany">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 添加子公司弹框 -->
      <el-dialog title="添加公司" :visible.sync="dialogVisible2" width="30%" :close-on-click-modal="false">
        <div>
          <table>
            <tr style="margin-bottom:10px">
              <td>
                <el-tag style="font-size:16px">上级公司</el-tag>
              </td>
              <td style="font-size:16px;padding-left:5px;font-family:'宋体';font-weight:600">{{ perName }}</td>
            </tr>

            <br>
            <tr>
              <td>
                <el-tag style="margin:0 15px 0 0;font-size:16px">公司名称</el-tag>
              </td>
              <td style="width:100%">
                <el-input v-model="company.name" placeholder="请输入公司名称">
                </el-input>
              </td>
            </tr>
          </table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="doAddCompany">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 编辑公司名称 -->
      <el-dialog :close-on-click-modal=false title="修改公司信息" :visible.sync="dialogVisible3" width="60%" :destroy-on-close=true custom-class="pub_dialog" style="background:rgba(0,0,0,0.6)">
        <div style="height:500px;overflow:scroll;scrollbar-width:none">
          <div style="font-size:16px;background:#eee;padding:5px 15px"><b>公司信息：</b></div><br>
          <el-form :model="companyMessage" :inline="true" status-icon :rules="rules" ref="companyMessage" label-width="auto" size="mini" class="demo-ruleForm">
            <div class="company-info">
              <!-- 图片上传 -->
              <div class="left">
                <div class="img">
                  <input type="file" @change="fileChange" ref="uploadimg" style="margin:10px 0;">
                  <!-- 图片 -->
                  <el-image :src="url" :preview-src-list="srcList">
                    <div slot="error" class="image-slot" style="width:200px">
                      <div style="text-align: center;margin-top:50px;color:#999">图片未上传</div>
                    </div>
                  </el-image>
                </div>

              </div>

              <div class="right">
                <el-form-item label="公司名称" prop="name1">
                  <el-input @input='change($event)' v-model="companyMessage.name1" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单位id" prop="ciId">
                  <el-input disabled v-model="companyMessage.ciId" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                  <el-input type="number" v-model="companyMessage.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮政编码" prop="yzbm">
                  <el-input type="number" v-model="companyMessage.yzbm" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="yx">
                  <el-input v-model="companyMessage.yx" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="成立时间" prop="clsj">
                  <el-date-picker type="date" placeholder="选择日期" v-model="companyMessage.clsj" style="width: 87%;" />
                </el-form-item>

                <el-form-item label="职工人数" prop="zgrs">
                  <el-input type="number" v-model="companyMessage.zgrs" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="所属行业" prop="sjzgdw" width="30%" :close-on-click-modal="false">
                  <el-select v-model="bindIndustrys" multiple placeholder="请选择所属行业" style="width: 100%;">
                    <el-option v-for="item in industryList" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="占地面积(㎡)" prop="zdmj">
                  <el-input type="number" v-model="companyMessage.zdmj" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="总建筑面积(㎡)" prop="zmj">
                  <el-input type="number" v-model="companyMessage.zmj" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="组织机构代码" prop="zjjgdm">
                  <el-input v-model="companyMessage.zjjgdm" autocomplete="off"></el-input>
                </el-form-item>

              </div>
            </div>

            <div style="font-size:16px;background:#eee;padding:5px 15px"><b>公司地址：</b></div><br>
            <br>
            <el-form-item label="选择公司地址">
              <el-button icon="el-icon-location" type="success" @click="deviceMap = true">坐标</el-button>
            </el-form-item>
            <!-- <el-form-item label="是否启用" prop="lat">
              <el-tooltip :content="enable?'当前状态：开启':'当前状态：关闭'" placement="top">
                <el-switch v-model="enable" active-color="#13ce66" inactive-color="#ccc" @change="swatch">
                </el-switch>
              </el-tooltip>
            </el-form-item> -->
            <el-form-item label="是否启用">
              <el-switch v-model="companyMessage.enable" active-color="#13ce66"></el-switch>
            </el-form-item>

            <el-form-item label="点位图标：">
              <!-- 单位   -->
              <input type="file" class="inpt" id="file" ref="uploadicon" @change="changeIcon">
              <!-- 图标 -->
              <span @click="changeIconbtn" class="icon" title="添加或修改图标">
                <el-image style="width: 35px;border-radius:5px" :src="iconUrl" fit="cover">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-upload" style="font-size:30px" title="上传图片"></i>
                  </div>
                </el-image>
              </span>
            </el-form-item>
            <br>

            <el-form-item label="详细地址" prop="address">
              <el-input v-model="companyMessage.address" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="经度" prop="lng">
              <el-input v-model="companyMessage.lng" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="纬度" prop="lat">
              <el-input v-model="companyMessage.lat" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="省" prop="sheng">
              <el-input v-model="companyMessage.sheng" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="市" prop="shi">
              <el-input v-model="companyMessage.shi" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="区" prop="qu">
              <el-input v-model="companyMessage.qu" autocomplete="off"></el-input>
            </el-form-item>

            <br>

            <div style="font-size:16px;background:#eee;padding:5px 15px"><b>主要负责人：</b></div><br>
            <el-form-item label="责任人姓名" prop="zrname">
              <el-input v-model="companyMessage.zrname" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="责任人电话" prop="zrphone">
              <el-input type="number" v-model="companyMessage.zrphone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="责任人身份证号" prop="zridcard">
              <el-input v-model="companyMessage.zridcard" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="管理人姓名" prop="glname">
              <el-input v-model="companyMessage.glname" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="管理人电话" prop="glphone">
              <el-input type="number" v-model="companyMessage.glphone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="管理人身份证号" prop="glidcard">
              <el-input v-model="companyMessage.glidcard" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="维保人" prop="zjzname">
              <el-input v-model="companyMessage.zjzname" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="维保人人电话" prop="zjzphone">
              <el-input type="number" v-model="companyMessage.zjzphone" autocomplete="off"></el-input>
            </el-form-item>
            <!-- <el-form-item label="专兼职管理人身份证号" prop="zjzcard">
              <el-input v-model="companyMessage.zjzcard" autocomplete="off"></el-input>
            </el-form-item> -->
            <el-form-item label="法人姓名" prop="frname">
              <el-input v-model="companyMessage.frname" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="法人电话" prop="frphone">
              <el-input type="number" v-model="companyMessage.frphone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="法人身份证号" prop="frcard">
              <el-input v-model="companyMessage.frcard" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="单位类别" prop="dwlb">
              <el-input v-model="companyMessage.dwlb" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="监管等级" prop="jgdj">
              <el-input v-model="companyMessage.jgdj" autocomplete="off"></el-input>
            </el-form-item>

            <!-- <el-form-item label="联网状态" prop="lwzt"  >
              <el-input v-model="companyMessage.lwzt" autocomplete="off"></el-input>
            </el-form-item> -->
            <el-form-item label="联网状态" prop="lwzt">
              <el-select v-model="companyMessage.lwzt" placeholder="请选择联网状态" style="width: 92%;">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="消防控制室电话" prop="xfkzspnone">
              <el-input type="number" v-model="companyMessage.xfkzspnone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="管辖单位名称" prop="gxdwname">
              <el-input v-model="companyMessage.gxdwname" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="入网日期" prop="rwrq">
              <el-date-picker type="date" placeholder="选择入网日期" v-model="companyMessage.rwrq" style="width: 87%;" />
            </el-form-item>
            <!-- <el-form-item label="入网日期" prop="rwrq"  >
              <el-input v-model="companyMessage.rwrq" autocomplete="off"></el-input>
            </el-form-item> -->
            <el-form-item label="经济所有制" prop="jjsyz">
              <el-input v-model="companyMessage.jjsyz" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="固定资产(万元)" prop="gdzc">
              <el-input v-model="companyMessage.gdzc" autocomplete="off"></el-input>
            </el-form-item>
            <!-- <el-form-item label="是否自动发送消息" prop="sfzdfsxx"  >
              <el-input v-model="companyMessage.sfzdfsxx" autocomplete="off"></el-input>
            </el-form-item> -->

            <el-form-item label="是否自动发送消息" prop="sfzdfsxx">
              <el-select v-model="companyMessage.sfzdfsxx" placeholder="请选择是否自动发送消息" style="width: 94%;">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否是VIP" prop="sfvip">
              <el-select v-model="companyMessage.sfvip" placeholder="请选择是否是VIP" style="width: 92%;">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="是否是VIP" prop="sfvip"  >
              <el-input v-model="companyMessage.sfvip" autocomplete="off"></el-input>
            </el-form-item> -->

            <el-form-item label="监管类别" prop="jglb">
              <el-input v-model="companyMessage.jglb" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="详细类型" prop="xxlx">
              <el-input v-model="companyMessage.xxlx" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- <el-input v-model="companyMessage.name1"></el-input> -->

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('companyMessage')">确 定</el-button>
        </span>
      </el-dialog>

      <el-tree :check-strictly="true" :data="allCompanyList" :props="defaultProps" default-expand-all :filter-node-method="filterNode" :expand-on-click-node="false" ref="tree" style="height: 700px;overflow-y:scroll">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button type="primary" plain size="mini" class="add" @click="() => addCompany(data)">
              添加
            </el-button>
            <el-button type="success" plain size="mini" class="add" @click="() => editCompany(data)">
              编辑
            </el-button>
            <el-button type="danger" plain size="mini" class="del" @click="() => delCompany(data.id)">
              删除
            </el-button>
          </span>
        </span>
      </el-tree>

      <el-dialog title="添加公司" :visible.sync="dialogVisible" width="30%">
        <div></div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="添加设备位置信息" :visible.sync="deviceMap" width="40%" style="background:rgba(0,0,0,0.6);">
        <div>
          <deviceMapPosition :rowId="rowId" @getPosition='getPosition' />
        </div>
      </el-dialog>

    </div>

  </div>
</template>

<script>
// 公司系统
import companySystem from "@/api/managementApi/CompanyInfo";
import companyMessage from "@/api/managementApi/CompanyMessage";

import BaseData from '@/assets/config/BaseData'
import Industry from "../../api/manageApi/Industry";
// 地图
import deviceMapPosition from '@/components/cloudPlatform/Center/deviceMapPosition'
import indestryAdministration from "../../api/manageApi/indestryAdministration";

export default {
  components: {
    deviceMapPosition,
  },
  data() {
    return {
      //   companyIcon: '',
      // 图片
      url: '',
      srcList: [],

      // 图标
      iconUrl: '',

      // ###########
      deviceMap: false,
      rowId: {
        type: 'companyInfo',
      },
      industryList: [],
      bindIndustrys: [],
      //   enable: false,

      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,

      allCompanyList: [], // 公司集合
      form: {
        info: "",
        // iscompany: "100",
      },
      company: {
        name: "",
        parentId: -1,
        // iscompany: "100",
      },
      companyMessage: {
        name1: "",
        parentId1: -1,
        enable: '',
        ciId: '',
        address: '',
        phone: '',
        sheng: '',
        shi: '',
        qu: '',
        zrname: '',
        zrphone: '',
        zridcard: "",
        glname: '',
        glphone: '',
        glidcard: '',
        zjzname: '',
        zjzphone: "",
        zjzcard: '',
        frname: '',
        frphone: '',
        frcard: '',
        dwlb: "",
        jgdj: '',
        yzbm: '',
        yx: '',
        zgrs: '',
        clsj: "",
        sjzgdw: '',
        zdmj: '',
        zmj: '',
        zjjgdm: '',
        lwzt: "",
        xfkzspnone: '',
        gxdwname: '',
        jjsyz: '',
        gdzc: '',
        rwrq: "",
        sfzdfsxx: '',
        lng: '',
        lat: '',
        sfvip: '',
        jglb: '',
        xxlx: ''
      },
      rules: {
        name1: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
      },
      company1: {
        name1: "",
        parentId1: -1,
      },
      perName: "",
      filterText: "",

      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },

      iconFiles: [], // 上传图标
      imgFiles: [], // 上传图片
    };
  },

  created() {
    this.getAllCompanyList();
    this.getIndustryList();
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  methods: {

    //圖片上传验证
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isSVG = file.type === "image/svg+xml";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!(isJPG || isPNG || isSVG || isJPEG)) {
        this.$message.error("上传图标只能是 JPG、PNG或SVG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图标大小不能超过 1MB!");
        return (isJPG || isPNG || isSVG) && isLt2M;
      }
    },

    // 选择公司图标 
    changeIconbtn() {
      const input = document.querySelector('#file');
      input.click();
    },
    changeIcon(e) {
      this.iconFiles = e.target.files[0];   // 文件
      let fileReader = new FileReader();
      let type = "default";
      this.beforeAvatarUpload(this.iconFiles);
      //判断文件类型
      if (/image/.test(this.iconFiles.type)) {
        fileReader.readAsDataURL(this.iconFiles);
        type = "image";
      } else {
        fileReader.readAsText(this.iconFiles, "utf-8");
        type = "text";
      }

      fileReader.onload = () => {
        this.iconUrl = fileReader.result;
      };
    },

    // 选择公司图片
    fileChange(e) {
      console.log(e);
      this.imgFiles = e.target.files[0];   // 文件

      let fileReader = new FileReader();
      let type = "default";

      this.beforeAvatarUpload(this.imgFiles);

      //判断文件类型
      if (/image/.test(this.imgFiles.type)) {
        fileReader.readAsDataURL(this.imgFiles);
        type = "image";
      } else {
        fileReader.readAsText(this.imgFiles, "utf-8");
        type = "text";
      }

      this.srcList = [];
      fileReader.onload = () => {
        this.url = fileReader.result;
        this.srcList.push(this.url)
      };
    },


    //   ###########
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    // 获取公司位置
    getPosition(value) {
      let _this = this;
      console.log(value);

      let myGeo = new BMapGL.Geocoder();
      myGeo.getLocation(new BMapGL.Point(value.lng, value.lat), function (result) {
        console.log(result);
        // 详细地址
        _this.companyMessage.address = result.address;
        // 经度
        _this.companyMessage.lng = result.point.lng;
        // 维度
        _this.companyMessage.lat = result.point.lat;
        // 省
        _this.companyMessage.sheng = result.addressComponents.city
        // 市
        _this.companyMessage.shi = result.addressComponents.district
        // 区
        _this.companyMessage.qu = result.addressComponents.province
      })
      this.$message.success("添加成功！")
      this.deviceMap = false

    },
    // 查询所有公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        //  console.log('1231231231',res.data.data);
        //   //循环列表 一级公司名字带 >?
        //   for (let i = 0; i < res.data.data.list.length; i++) {
        //   let str = res.data.data.list[i].name
        //   str = str.replace(/>/g,'')
        //   res.data.data.list[i].name = str
        //   //二级带>?
        //       for (let a = 0; a < res.data.data.list[i].smallCompanyList.length; a++) {
        //       let stra =  res.data.data.list[i].smallCompanyList[a].name
        //       stra = stra.replace(/>/g,'')
        //       res.data.data.list[i].smallCompanyList[a].name = stra 
        //   //三级 >?
        //         for (let b = 0; b < res.data.data.list[i].smallCompanyList[a].smallCompanyList.length; b++) {
        //           let strb = res.data.data.list[i].smallCompanyList[a].smallCompanyList[b].name
        //           strb = strb.replace(/>/g,'')
        //           res.data.data.list[i].smallCompanyList[a].smallCompanyList[b].name = strb
        //         }
        //       }
        //   }
        this.allCompanyList = res.data.data.list;
      });
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 添加新的顶级公司
    AddNewCompany() {
      // 处理添加过程
      // if (this.form.iscompany == "100") {
      //     this.form.info = '>' + this.form.info
      // }else {
      //     this.form.info = this.form.info
      // }
      this.form.info = this.form.info
      if (this.form.info != "") {
        companySystem.addHighestCompanyInfo(this.form.info).then(() => {
          // console.log(res);
          this.dialogVisible1 = false;
          this.form.info = "";
          this.getAllCompanyList();
        });
      } else {
        this.$message.error("系统名称不能为空！");
      }
    },

    // 添加子公司
    addCompany(data) {
      this.company.parentId = data.id;
      this.perName = data.name;
      this.dialogVisible2 = true;
    },
    doAddCompany() {
      // companySystem.addSmallCompanyInfo
      // 处理添加过程
      // if (this.company.iscompany == "100") {
      //     this.company.name = '>' + this.company.name
      // }else {
      //     this.company.name = this.company.name
      // }
      this.company.name = this.company.name
      if (this.company.name != "") {
        companySystem
          .addSmallCompanyInfo(this.company.parentId, this.company.name)
          .then(() => {
            // console.log(res);
            this.dialogVisible2 = false;
            this.company.name = "";
            this.getAllCompanyList();
          });
      } else {
        this.$message.error("系统名称不能为空！");
      }
    },

    // swatch(value) {
    //   console.log(value);editIndustrybian
    //   if (value) {
    //     this.companyMessage.enable = '1'
    //   } else {
    //     this.companyMessage.enable = '0'
    //   }

    //   console.log(this.companyMessage);
    // },

    // 删除公司
    delCompany(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        companySystem.deleteCompanyInfo(id).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getAllCompanyList();
        });
      });
    },
    getIndustryList() {
      indestryAdministration.getIndustryList().then((res) => {
        console.log(res);
        this.industryList = res.data.data.industryInfoList;
      })
    },

    change(e) {
      this.$forceUpdate()
    },

    editCompany(data) {
      console.log(data.id);
      // 回显行业
      Industry.getIndustrysByName("ciId", data.id).then((res) => {
        this.bindIndustrys = res.data.data.industrys;
        console.log(this.bindIndustrys);
      })
      console.log(data);
      this.srcList = []
      this.companyMessage = {};
      this.companyMessage.name1 = data.name;
      this.companyMessage.ciId = `${data.id}`; // num 转string
      console.log(this.companyMessage);
      // 列表全部信息
      companyMessage.getCompanyMessage(this.companyMessage).then((res) => {
        console.log(res);
        this.companyMessage = res.data.data.companyMessage;
        this.url = BaseData.baseUrl + this.companyMessage.companyImg
        this.srcList.push(this.url)
        this.iconUrl = BaseData.baseUrl + this.companyMessage.companyIcon

        if (this.companyMessage.enable == "1") {
          this.companyMessage.enable = true
        } else {
          this.companyMessage.enable = false
        }

        this.companyMessage.name1 = data.name;
      })



      this.dialogVisible3 = true;

    },

    submitForm(companyMessage) {
      // 启用开关转换
      if (this.companyMessage.enable) {
        this.companyMessage.enable = "1"
      } else {
        this.companyMessage.enable = "0"
      }

      this.$refs[companyMessage].validate((valid) => {
        if (valid) {
          //保存公司基本信息
          this.doEditCompany()
          // 保存公司绑定行业
          this.editDepartmentIndustry()
        } else {
          return false;
        }
      });

    },

    doEditCompany() {

      if (this.companyMessage.name1 != "") {
        const formData = new FormData();
        formData.append("ciId", this.companyMessage.ciId);

        if (this.imgFiles.length != 0) {
          formData.append("companyImgFile", this.imgFiles);
        }

        if (this.iconFiles.length != 0) {
          formData.append("companyIconFile", this.iconFiles);
        }

        if (this.bindIndustrys.length > 0) {
          for (let i = 0; i < this.bindIndustrys.length; i++) {
            if (i == 0) {
              this.companyMessage.sjzgdw += this.bindIndustrys[i];
            } else {
              this.companyMessage.sjzgdw += "-" + this.bindIndustrys[i];
            }
          }
        }


        // formData.forEach((value, key) => {
        //   console.log(key, value);
        // })

        companySystem.updateCompanyInfo(this.companyMessage.ciId, this.companyMessage.name1).then((res) => {
          companyMessage.updateCompanyMessage(this.companyMessage).then((res) => {
            if (formData.length != 1) {
              companyMessage.saveCompanyImg(formData).then(res => {
                console.log(res);
                // 提交提示信息
                this.$message({
                  type: res.data.success ? "success" : "warning",
                  //   message: res.data.message,
                  message: "修改成功！",
                });
                this.$refs.uploadicon.value = null//清除文件信息
                this.$refs.uploadimg.value = null//清除文件信息
                this.imgFiles = []
                this.iconFiles = []
              })
            } else {
              this.$message({
                type: res.data.success ? "success" : "warning",
                message: res.data.message,
              });
            }

          })
          this.dialogVisible3 = false;
          this.getAllCompanyList();
        });
      } else {
        this.$message.error("公司名称不能为空！");
      }
    },
    // 保存公司绑定行业
    editDepartmentIndustry() {
      Industry.editDepartmentIndustry(this.companyMessage.ciId, this.bindIndustrys)
    }
  },
};
</script>

<style lang="scss" scoped>
#app {
  box-sizing: border-box;
  padding: 10px 5px;
}
// input type=number时 去掉后面上下按钮
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}

.add,
.del {
  font-size: 16px;
  padding: 2px 5px;
}

/deep/.el-dialog__title {
  font-size: 24px;
  font-weight: 600;
  color: #555;
}

.company-info {
  width: 100%;
  box-sizing: border-box;
  //   border: 1px solid red;
  display: flex;

  // height: 100px;
  .left {
    width: 30%;
    height: 282px;

    // border: 1px solid #aaa;
    .img {
      margin-left: 20%;
      box-sizing: border-box;
      width: 70%;
      height: 282px;
      //   border: 1px solid #aaa;
    }
  }

  .right {
    width: 70%;
    // border: 1px solid rgb(247, 110, 110);
  }
}

.inpt {
  width: 40%;
  //   opacity: 0.2;
  display: none;
}

.upload {
  margin: 10px;
  cursor: pointer;
}

.icon:hover {
  color: aquamarine;
}
</style>