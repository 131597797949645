<template>
  <div id="map">
    <div v-if="rowId">
      <baidu-map @click="handler($event)" class="map" :zoom="zoom" center="天安门" :scroll-wheel-zoom="true">
        <bm-view class="map"></bm-view>
        <bm-local-search :keyword="keyword" :auto-viewport="true" :location="location" class="aaa" v-if="keyword!=''"></bm-local-search>
        <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" type="BMAP_NAVIGATION_CONTROL_LARGE" :offset="{width: 10,height:60}"></bm-navigation>
        <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" type="BMAP_NAVIGATION_CONTROL_PAN" :offset="{width: 35,height:10}"></bm-navigation>

        <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type>
        <bm-marker :position="position" :dragging="true"></bm-marker>

      </baidu-map>
      <el-input v-model="keyword" placeholder="输入关键字进行搜索..." class="searchAddress"></el-input>

      <div class="btn">
        <el-button type="success" size="small" @click="submit">提交坐标</el-button>
      </div>
    </div>
  </div>
</template>
<script>

import BaseData from "../../../assets/config/BaseData";
import jQuery from 'jquery'
import $ from 'jquery'

export default {
  props: ['rowId'],
  data() {
    return {
      keyword: '',
      baseURL: BaseData.baseUrl,
      zoom: 15,
      position: {},
    };
  },
  created() {
    console.log(this.rowId);
  },

  methods: {
    //   添加点
    handler(e) {
      this.position = e.point
    },

    // 提交坐标
    submit() {
      let _this = this
      // 添加公司点位
      if (this.rowId.type == 'companyInfo') {
        console.log(this.position);
        this.$emit('getPosition', this.position);
      }
      // 添加设备点位
      else {
        let obj = "{'1':{'lng':'" + this.position.lng + "','lat':'" + this.position.lat + "'}}"
        jQuery.ajax({
          url: this.baseURL + "Zz3dmapBuildingController/saveDeviceLoLa",
          type: "POST",
          async: true,
          dataType: "json",
          data: $.param({
            deviceId: this.rowId,
            buildingMarks: obj,
          }),
          success: function (result) {
            if (result == false) {
              // alert("添加失败")
              _this.$message.warning("您未选择任何位置信息，添加失败！")
              _this.$emit("send", false);
            }
            if (result == true) {
              _this.$message.success("添加成功")
              _this.$emit("send", true);
            }
          }
        })
      }
    },

    // 搜索地址
    searchInfo() {
      console.log(this.keyword);
    },


  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 440px;
}
.btn {
  position: absolute;
  top: 20%;
  right: 0;
  margin: 10px 18px;
}
/deep/.anchorBL {
  display: none;
}
.aaa {
  position: absolute;
  width: 230px;
  top: 21%;
  left: 3%;
  height: 400px !important;
  overflow: scroll;
}
.searchAddress {
  width: 30%;
  position: absolute;
  top: 13%;
  left: 3%;
}
::-webkit-scrollbar {
  display: none;
}
</style>