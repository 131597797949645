import request from '@/../utils/request'
const api_name = '/companyMessage'

export default {

    // 根据公司id查询
    getCompanyMessage(companyMessage) {
        return request({
            url: `${api_name}/getCompanyMessage`,
            header: "application/json;charset=UTF-8",
            method: 'post',
            data: companyMessage,
        })
    },
    //    根据对象修改信息
    updateCompanyMessage(companyMessage) {
        return request({
            url: `${api_name}/updateCompanyMessage`,
            header: "application/json;charset=UTF-8",
            method: 'post',
            data: companyMessage,
        })
    },

    getAllPositionCompany() {
        return request({
            url: `${api_name}/getAllPositionCompany`,
            method: 'get'
        })
    },

    saveCompanyImg(formData) {
        return request({
            url: `${api_name}/saveCompanyImg`,
            method: 'post',
            data: formData
        })
    }
}